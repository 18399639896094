<script>
	import { gdprConsent } from '$lib/stores/gdprConsent';
	import { initSmartlook, smartlookClient } from '$lib/utils/smartlook.js';
	import { onMount } from 'svelte';
	import { afterNavigate } from '$app/navigation';
	import { page } from '$app/stores';
	import { browser } from '$app/environment';

	$: consent = $gdprConsent.consent;

	const startRecording = () => {
		if (consent) {
			smartlookClient.record({ emails: true, forms: true, ips: true, numbers: true });
		} else {
			smartlookClient.record();
		}
	};

	onMount(() => {
		initSmartlook();
		startRecording();
	});

	$: {
		if (browser && consent) {
			startRecording();
		}
	}

	afterNavigate(() => {
		smartlookClient.navigation($page.url.pathname);
	});
</script>
