import {
	getProducts,
	getArticles,
	extractFeaturedProducts,
	extractSingleFeaturedProduct
} from '$lib/utils/contentful';

export async function load({ fetch }) {
	try {
		const products = await getProducts(fetch);
		const articles = await getArticles(fetch);
		const featuredProducts = extractFeaturedProducts(products);
		const featuredProduct = extractSingleFeaturedProduct(featuredProducts);

		return {
			featuredProduct,
			products,
			articles
		};
	} catch (error) {
		return { featuredProduct: null, products: null, articles: null };
	}
}

export const prerender = 'auto';
