import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { createSlug, getCountryFromCode } from './helpers';

const options = {
	renderNode: {
		[BLOCKS.EMBEDDED_ASSET]: (node) => {
			const { url, details } = node.data.target.fields.file;
			const { width, height } = details.image;
			return `<img src="${url}" width="${width}" height="${height}" style="max-width: 100%; height: auto" alt="${node.data.target.fields.title}"/>`;
		}
	}
};

export const transformArticle = (article) => {
	return {
		...article.fields,
		contentHtml: documentToHtmlString(article.fields.body, options),
		featuredImage: {
			src: article.fields.featuredImage.fields.file.url,
			alt: article.fields.featuredImage.fields.title,
			width: article.fields.featuredImage.fields.file.details.image.width,
			height: article.fields.featuredImage.fields.file.details.image.height
		}
	};
};

export const transformProduct = (product) => {
	const transformedVariants = [];

	for (const variantEntry of product.variants) {
		try {
			const variant = variantEntry.fields;

			const primaryImage = variant.primaryImage?.fields;
			const secondaryImage = variant.secondaryImage?.fields;

			const additionalImages =
				variant.additionalImages?.map((image) => {
					const additionalImageFields = image.fields;
					return {
						src: additionalImageFields.file.url,
						alt: additionalImageFields.title,
						width: additionalImageFields.file.details?.image.width,
						height: additionalImageFields.file.details?.image.height
					};
				}) ?? [];

			const swatch = variant.swatch?.fields?.file.url || null;
			const description = variant.description || null;

			transformedVariants.push({
				id: product.id,
				name: variant.name,
				slug: createSlug(variant.name),
				primaryImage: primaryImage
					? {
							src: primaryImage.file.url,
							alt: primaryImage.title,
							width: primaryImage.file.details?.image.width,
							height: primaryImage.file.details?.image.height
					  }
					: null,
				secondaryImage: secondaryImage
					? {
							src: secondaryImage.file.url,
							alt: secondaryImage.title,
							width: secondaryImage.file.details?.image.width,
							height: secondaryImage.file.details?.image.height
					  }
					: null,
				additionalImages,
				swatch,
				description
			});
		} catch (error) {
			console.error('Error transforming variant:', error);
		}
	}

	let transformedProduct = null;

	try {
		const categoryFields = product.category?.fields;

		transformedProduct = {
			name: product.name || null,
			description: product.description || null,
			listPrice: product.listPrice || null,
			productType: product.productType || null,
			slug: product.slug || null,
			sizes: product.sizes || null,
			details: product.details || null,
			sizeChart: product.sizeChart || null,
			category: {
				name: categoryFields?.name || null,
				description: categoryFields?.description || null,
				bannerImage: categoryFields
					? {
							src: categoryFields.bannerImage?.fields?.file.url || null,
							alt: categoryFields.bannerImage?.fields?.title || null,
							width: categoryFields.bannerImage?.fields?.file.details?.image.width || null,
							height: categoryFields.bannerImage?.fields?.file.details?.image.height || null
					  }
					: null
			},
			featuredImages:
				product.featuredImages?.map((image) => {
					const featuredImageFields = image.fields;
					return {
						src: featuredImageFields.file.url,
						alt: featuredImageFields.title,
						width: featuredImageFields.file.details?.image.width,
						height: featuredImageFields.file.details?.image.height
					};
				}) ?? [],
			variants: transformedVariants
		};
	} catch (error) {
		console.error('Error transforming product:', error);
	}

	return transformedProduct;
};

export const transformSquarePayment = (payment) => {
	const {
		id: paymentId,
		order,
		receipt_number,
		amount_money,
		status: paymentStatus,
		source_type,
		card_details,
		refunded_money,
		updated_at: paymentLastUpdated,
		buy_now_pay_later_details
	} = payment;

	const {
		id: orderId,
		line_items,
		state: orderStatus,
		version: orderVersion,
		created_at: orderCreatedAt,
		updated_at: orderLastUpdated
	} = order;

	const { recipient, carrier, tracking_number, shipped_at, tracking_url, canceled_at } =
		order.fulfillments[0].shipment_details;

	const { uid, state: shipmentState } = order.fulfillments[0];

	const { amount: processingFee } = payment.processing_fee[0].amount_money;

	const { amount: deliveryAmount } = order.service_charges[0].amount_money;

	return {
		payment: {
			paymentId,
			receipt: receipt_number,
			total: amount_money.amount / 100,
			fee: processingFee / 100,
			delivery: deliveryAmount / 100,
			refunded: refunded_money?.amount / 100 || 0,
			paymentStatus: paymentStatus.toLowerCase(),
			sourceType: source_type.toLowerCase(),
			cardDetails:
				source_type === 'CARD'
					? {
							cardBrand: card_details?.card?.card_brand.toLowerCase(),
							lastFour: card_details?.card?.last_4,
							expiryMonth: card_details?.card?.exp_month,
							expiryYear: card_details?.card?.exp_year
					  }
					: null,
			paymentMethodLabel:
				source_type === 'CARD'
					? `${card_details.card.card_brand.toLowerCase().replace('_', ' ')} ending in ${
							card_details.card.last_4
					  }`
					: buy_now_pay_later_details.brand.toLowerCase(),
			paymentLastUpdated: new Date(paymentLastUpdated).toLocaleDateString('en-GB')
		},
		order: {
			orderId,
			orderStatus: orderStatus.toLowerCase(),
			orderVersion,
			items: line_items.map((item) => ({
				name: item.name,
				colour: item.variation_name,
				size: item.note,
				quantity: item.quantity,
				total: item.total_money.amount / 100
			})),
			isOrderOpen: orderStatus === 'OPEN',
			orderCreatedAt: new Date(orderCreatedAt).toLocaleDateString('en-GB'),
			orderCreatedAtTime: new Date(orderCreatedAt).toLocaleTimeString('en-GB', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			}),
			orderLastUpdated: new Date(orderLastUpdated).toLocaleDateString('en-GB'),
			daysSinceOrder: Math.floor((Date.now() - new Date(orderCreatedAt)) / (1000 * 60 * 60 * 24)),
			orderNotes: order?.metadata?.notes || undefined
		},
		customer: {
			name: recipient.display_name.toLowerCase(),
			email: recipient.email_address.toLowerCase(),
			phone: recipient.phone_number,
			address: {
				addressLine1: recipient.address.address_line_1.toLowerCase(),
				addressLine2: recipient.address.address_line_2.toLowerCase(),
				locality: recipient.address.locality.toLowerCase(),
				postalCode: recipient.address.postal_code.toUpperCase(),
				country: getCountryFromCode(recipient.address.country.toUpperCase()).toLowerCase()
			}
		},
		shipment: {
			uid,
			shipmentState,
			carrier,
			trackingNumber: tracking_number,
			trackingUrl: tracking_url,
			shippedAt: shipped_at ? new Date(shipped_at).toLocaleDateString('en-GB') : null,
			cancelledAt: canceled_at ? new Date(canceled_at).toLocaleDateString('en-GB') : null,
			daysSinceShipped: shipped_at
				? Math.floor((Date.now() - new Date(shipped_at)) / (1000 * 60 * 60 * 24))
				: null,
			daysBetweenShippedAndCreated:
				shipped_at && orderCreatedAt
					? Math.floor((new Date(shipped_at) - new Date(orderCreatedAt)) / (1000 * 60 * 60 * 24))
					: null
		}
	};
};
