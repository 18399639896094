import { PUBLIC_CONTENTFUL_SPACE_ID, PUBLIC_CONTENTFUL_ACCESS_TOKEN } from '$env/static/public';
import { transformProduct, transformArticle } from './transformers';
import resolveResponse from 'contentful-resolve-response';

const CONTENTFUL_BASE_URL = `https://cdn.contentful.com/spaces/${PUBLIC_CONTENTFUL_SPACE_ID}/environments/master/entries`;
const CONTENTFUL_GRAPHQL_URL = `https://graphql.contentful.com/content/v1/spaces/${PUBLIC_CONTENTFUL_SPACE_ID}`;

async function handleRateLimit(response) {
	if (response.status === 429) {
		const resetTime = parseInt(response.headers.get('X-Contentful-RateLimit-Reset'));
		const waitTime = (resetTime + 1) * 1000;
		await new Promise((resolve) => setTimeout(resolve, waitTime));
		return true;
	}
	return false;
}

export async function getContentfulEntries(fetch, query) {
	const url = new URL(CONTENTFUL_BASE_URL);
	url.searchParams.append('access_token', PUBLIC_CONTENTFUL_ACCESS_TOKEN);

	Object.entries(query).forEach(([key, value]) => {
		url.searchParams.append(key, value);
	});

	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'public, max-age=31536000'
			}
		});

		const rateLimitHandled = await handleRateLimit(response);

		if (rateLimitHandled) {
			return getContentfulEntries(fetch, query);
		}

		if (!response.ok) {
			throw new Error('Error fetching Contentful entries');
		}

		return await response.json();
	} catch (error) {
		console.error('Error fetching Contentful entries:', error);
		throw error;
	}
}

export async function getGraphQLContentfulData(fetch, query) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
			'Cache-Control': 'public, max-age=31536000'
		},
		body: JSON.stringify({ query })
	};

	try {
		const response = await fetch(CONTENTFUL_GRAPHQL_URL, requestOptions);

		const rateLimitHandled = await handleRateLimit(response);

		if (rateLimitHandled) {
			return getGraphQLContentfulData(query);
		}

		return await response.json();
	} catch (error) {
		console.error('Error fetching Contentful GraphQL data:', error);
		throw error;
	}
}

export async function getArticles(fetch) {
	const query = {
		content_type: 'article'
	};

	try {
		const response = await getContentfulEntries(fetch, query);
		const resolvedResponse = resolveResponse(response);
		const transformedArticles = resolvedResponse.map((article) => transformArticle(article));

		return transformedArticles;
	} catch (error) {
		console.error('Error fetching articles:', error);
		throw error;
	}
}

export async function getProductPrices(fetch) {
	const query = `
		{
			productCollection {
				items {
					name
					listPrice
				}
			}
		}
	`;

	try {
		const response = await getGraphQLContentfulData(fetch, query);
		const products = response.data.productCollection.items;

		return products;
	} catch (error) {
		console.error('Error fetching product prices:', error);
		throw error;
	}
}

export const getProducts = async (fetch) => {
	const query = {
		content_type: 'product'
	};

	try {
		const response = await getContentfulEntries(fetch, query);
		const resolvedResponse = resolveResponse(response);

		const products = resolvedResponse.map((item) => {
			return {
				id: item.sys.id,
				...item.fields
			};
		});

		const tranformedProducts = products.map((product) => transformProduct(product));

		return tranformedProducts;
	} catch (error) {
		console.error('Error fetching all products:', error);
		throw error;
	}
};

export const extractFeaturedProducts = (products) => {
	const featuredProducts = [];
	products.forEach((product) => {
		if (product.featuredImages.length > 0) {
			featuredProducts.push(product);
		}
	});
	return featuredProducts;
};

export const extractSingleFeaturedProduct = (featuredProducts) => {
	const today = new Date();
	const day = today.getDate();
	const month = today.getMonth();
	const year = today.getFullYear();

	const seed = day + month + year;
	const randomIndex = seed % featuredProducts.length;

	const randomImageIndex = (seed + 1) % featuredProducts[randomIndex].featuredImages.length;
	const featuredProduct = featuredProducts[randomIndex];

	return {
		name: featuredProduct.name,
		description: featuredProduct.description,
		productType: featuredProduct.productType,
		slug: featuredProduct.slug,
		category: featuredProduct.category.name,
		image: {
			src: featuredProduct.featuredImages[randomImageIndex].src,
			alt: featuredProduct.featuredImages[randomImageIndex].alt,
			width: featuredProduct.featuredImages[randomImageIndex].width,
			height: featuredProduct.featuredImages[randomImageIndex].height
		}
	};
};
